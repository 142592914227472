import React from 'react'

import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { Icon, Img, Square, Text, Stack } from '@chakra-ui/react';
import Logo from '../assets/img/vazcologo.jpg'


function Navbar() {
  return (
    <Stack width='100%' height='90px' top='0' position='relative' borderBottom='1px solid #888888' zIndex='2' backgroundColor='white' justifyContent='center' alignItems='center'>
      <Stack width='80%' direction='row' justifyContent='space-between' alignItems='center' spacing={20}>
        <Stack>
          <Link to='/'>
            <Img src={Logo} w='160px' />
          </Link>
        </Stack>
        <Stack w='100%' direction='row' justifyContent='center' alignItems='center' spacing={30} display={{
          base: 'none',
          md: 'bock'
        }}>
          <Link to={'#nosotros'}><Text letterSpacing='1.3px' fontFamily='Helvetica' fontSize={12} fontWeight='600' >NOSOTROS</Text></Link>
          <Link to={'#porquevazco'}><Text letterSpacing='1.3px' fontFamily='Helvetica' fontSize={12} fontWeight='600' >POR QUE VAZCO</Text></Link>
          <Link to={'#servicios'}>
            <Text letterSpacing='1.3px' fontFamily='Helvetica' fontSize={12} fontWeight='600' >SERVICIOS</Text>
          </Link>
          {/* <Link to={'/blog'}>
            <Stack direction='row' spacing={6} alignItems='center'>
              <Text letterSpacing='1.3px' fontFamily='Helvetica' fontSize={12} fontWeight='600' >BlOG</Text>
              <Icon as={FaAngleDown} />
            </Stack>
          </Link> */}
          <Link to={'#contacto'} ><Text letterSpacing='1.3px' fontFamily='Helvetica' fontSize={12} fontWeight='600' >CONTACTO</Text></Link>
        </Stack>
        <Stack direction='row' alignItems='center' justifyContent='center' spacing={5}>
          <a href={'https://www.facebook.com/VAZCOsolar'}>
            <Square size='40px' bg='#2B9B1F' color='white' borderRadius={'5px'}>
              <Icon as={FaFacebookF} boxSize={18} />
            </Square>
          </a>
          <a href={'https://www.instagram.com/vazco.solar/'}>
            <Square size='40px' bg='#2B9B1F' color='white' borderRadius={'5px'}>
              <Icon as={FaInstagram} boxSize={18} />
            </Square>
          </a>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Navbar