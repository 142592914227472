import { Stack, Text, Textarea, Input, Select, Button } from '@chakra-ui/react'
import photo2 from '../assets/img/photo2.jpg'
import photo4 from '../assets/img/photo4.jpg'
import { MdArrowDropDown } from "react-icons/md";
import { useState } from 'react';

function SectionMakeAppointment() {
    const [service, setService] = useState<string>();
    const [customMessage, setCustomMessage] = useState<string>();

    const sendWhatsappMessage = () => {
        const message = `Hola, me gustaría recibir más información sobre ${service} y tengo la siguiente duda: ${customMessage}`
        const phone = '3123032690'
        window.open(`https://wa.me/${phone}?text=${message}`)
    }
    return (
        <Stack width={'100%'} h={'auto'} >
            <Stack width='100%' alignItems='center'
                p={{
                    base: 0,
                    md: 20
                }} mt={20} backgroundSize='cover'
                backgroundAttachment='fixed'
                backgroundImage={`linear-gradient( rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(${photo2})`}>
                <Stack direction={'row'} spacing={20} w='80%' h={'auto'} mt={10}>
                    <Stack w={{
                        base: 'auto',
                        md: '50%'
                    }} mt={10} >
                        <Stack backgroundColor='#2B9B1F' borderRadius='1px 1px 5px 5px' marginBottom='10px' padding='0px 20px 10px 20px' textAlign='center' w={'300px'}>
                            <Text color='#FFF' fontSize='14px' fontWeight='600' lineHeight='1.2em' letterSpacing='1.5px' marginTop='-8px'>ENERGIAS RENOVABLES</Text>
                        </Stack>
                        <Stack textAlign={'left'} spacing={10} marginBottom={40}>
                            <Text color={'#FFF'} fontSize={36} fontWeight={600} lineHeight={'1.2em'} textTransform={'capitalize'}>¿Necesitas mas informacion? Con gusto de atendemos</Text>
                            <Text color={'#C4C4C4'} fontSize={16} fontWeight={400} lineHeight={'1.5em'} >Entendemos que dar el paso hacia la energía solar puede venir con muchas preguntas y consideraciones. Por eso, en Vazco Solar, ofrecemos consultorías gratuitas para ayudarte a entender mejor las ventajas de la energía solar, cómo funciona, y cómo puede beneficiarte personal y económicamente. Nuestros expertos están aquí para guiar, informar y apoyarte en cada fase de tu transición solar.</Text>
                        </Stack>
                    </Stack>
                    <Stack mb={{
                        base: 0,
                        md: 40
                    }} />
                </Stack>
            </Stack>
            <Stack w={'100%'} justifyContent={'center'} alignItems={'center'} position='relative' mt={{
                base: -10,
                md: "-180px"
            }} pb={100}>

                <Stack spacing={0} w={{
                    base: '100%',
                    md: '80%'
                }}
                    backgroundImage={{
                        base: 'none',
                        md: `linear-gradient( rgba(0,0,0,0.4),rgba(0,0,0,0.8)),url(${photo4})`
                    }}
                    position={'relative'}
                    borderRadius={{
                        base: 0,
                        md: '20px'
                    }}
                    direction={'row-reverse'}
                    boxShadow='rgb(0 0 0 / 80%) 0px 5px 9px'

                    backgroundPosition={'center'}
                    backgroundSize={'cover'}>

                    <Stack alignItems={'center'} bg={'#FFF'} w={{
                        base: '100%',
                        md: '50%'
                    }} borderRadius={{
                        base: 0,
                        md: '0px 20px 10px 0px'
                    }} p={10} justifyContent={'center'}>
                        <form>
                            <Stack spacing={10} width={'350px'} >
                                <Select onChange={(e) => {
                                    setService(e.target.value)
                                }} icon={<MdArrowDropDown />} placeholder='Selecciona un servicio'>
                                    <option value="Paneles Solares">Paneles Solares</option>
                                    <option value="Sistemas Aislados">Sistemas Aislados</option>
                                    <option value="Colectores Solares">Colectores Solares</option>
                                    <option value="Calentadores Solares">Calentadores Solares</option>
                                </Select>
                                <Textarea
                                    placeholder='Comentarios'
                                    onChange={(e) => {
                                        setCustomMessage(e.target.value)
                                    }}
                                    border={'1px solid gray'}
                                    borderRadius={'6px'}
                                    p={4}
                                    outline='0'
                                />
                                <Button
                                    onClick={sendWhatsappMessage}
                                    p='20px 40px 20px 40px'
                                    fontFamily={'sans-serif'}
                                    fontSize={'14px'}
                                    fontWeight='600'
                                    lineHeight='1em'
                                    letterSpacing={'1.5px'}
                                    color={'#FFF'}
                                    bg={'#2B9B1F'}
                                    borderStyle={'none'}
                                    borderRadius={'5px'}
                                    boxShadow=' 0px 0px 0px 0px rgb(66 133 244 / 30%)'
                                    _hover={{ bg: '#181C28', transition: 'all 0.5s' }}
                                >
                                    ENVIAR
                                </Button>
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>

    )
}

export default SectionMakeAppointment