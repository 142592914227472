import React from 'react'

import './App.css'
import HomeScreen from './screens/HomeScreen';
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HomeScreen />
      </BrowserRouter>
    </div>

  );
}

export default App;
