import { Box, Button, Fade, Image, Link } from "@chakra-ui/react";
import { Stack, Text } from "@chakra-ui/react";
import Navbar from "../Navbar";
import "./Banner.css";
import Photo1 from "./img/foto1.jpeg";
import Photo2 from "./img/foto2.jpeg";
import Photo3 from "./img/foto3.jpeg";
import { useState } from "react";
import { useEffect } from "react";

const ImageData = [
  {
    photo: Photo1,
  },
  {
    photo: Photo2,
  },
  {
    photo: Photo3,
  },
];

const BackgroundAnimation = () => {
  //create timer to change image every 5 seconds
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => {
        if (prev === ImageData.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const image = ImageData[index];

  return (
    <Box position={"absolute"} width={"100%"} background="black" maxH={700}>
      <Fade in={true} style={{ zIndex: 10 }}>
        <Box height={700}>
          <Image
            _before={{
              content: `""`,
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity: "0.6",
            }}
            src={image.photo}
            width="100%"
            height="100%"
            objectFit="cover"
          />
          <Box
            style={{
              backgroundColor: "black",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0.5,
            }}
          ></Box>
        </Box>
      </Fade>
    </Box>
  );
};

const Banner = () => {
  const contactThroughWhatsapp = () => {
    window.open("https://wa.me/3123032690", "_blank");
  };

  return (
    <Stack
      width="100%"
      top="0"
      className="img-banner"
      overflow="hidden"
      background="white"
      position="relative"
    >
      <Stack width="100%" height="100%" zIndex="0">
        <BackgroundAnimation />
        {/*  N A V B A R */}
        <Navbar />
        <Stack
          py={20}
          px={{
            base: 5,
            md: "10%",
          }}
          width={{
            base: "100%",
            md: "80%",
          }}
          position="relative"
          alignItems="flex-start"
          zIndex="1"
        >
          <Stack
            backgroundColor="#2B9B1F"
            borderRadius="0px 0px 5px 5px"
            marginBottom="10px"
            padding="0px 20px 10px 20px"
          >
            <Text
              color="#fff"
              fontSize="14px"
              fontWeight="600"
              lineHeight="1.2em"
              letterSpacing="1.5px"
              marginTop="-8px"
            >
              DESDE 2018
            </Text>
          </Stack>
          <Stack marginBottom="20px">
            <Text
              fontSize={{
                base: "36px",
                md: "60px",
              }}
              fontWeight="600"
              textAlign="left"
              lineHeight="1.1em"
              color="#FFFFFF"
              paddingBottom="10px"
            >
              Cotiza sin compromiso y comienza a invertir en tu futuro
            </Text>
            <Text
              textAlign="left"
              fontSize="16px"
              color="#FFFFFF"
              paddingRight="30px"
            >
              En Vazco Solar, nuestra pasión es iluminar el futuro de Colima con
              energía limpia y renovable.
            </Text>
          </Stack>
          <Stack
            direction={{
              base: "column",
              md: "row",
            }}
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
            paddingTop="20px"
            spacing={{
              base: 5,
              md: 20,
            }}
          >
            <Button
              padding="8px"
              size="md"
              color="#FFFFFF"
              height="48px"
              px={10}
              width={{
                base: "100%",
                md: "auto",
              }}
              backgroundColor="#2B9B1F"
              borderRadius="5px 5px 5px 5px"
              fontWeight="600"
              fontSize="14px"
              letterSpacing="1.5px"
              onClick={contactThroughWhatsapp}
              className="button-contacto"
            >
              CONTACTO
            </Button>
            <Button
              padding="8px"
              size="md"
              color="#2B9B1F"
              height="48px"
              px={10}
              width={{
                base: "100%",
                md: "auto",
              }}
              backgroundColor="#FFFFFF"
              borderRadius="5px 5px 5px 5px"
              fontWeight="600"
              fontSize="14px"
              letterSpacing="1.5px"
              className="button-servicio"
            >
              <Link textDecor={"none"} href="#servicios">
                NUESTRO SERVICIOS
              </Link>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Banner;
