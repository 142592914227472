import React from "react";

import { Box, Icon, Link, Stack, Text } from "@chakra-ui/react";
import { FiPhoneCall } from "react-icons/fi";
import { TfiMapAlt } from "react-icons/tfi";
import { BsPlayCircle } from "react-icons/bs";

function SectionInformation() {
  return (
    <Stack w="100%" alignItems="center" justifyContent="center">
      <Stack
        direction={{
          base: "column",
          md: "row",
        }}
        w="90%"
        alignItems="flex-start"
        py={10}
        justifyContent="space-between"
      >
        <Stack
          flex={1}
          direction="row"
          justifyContent="center"
          spacing={30}
          textAlign="left"
          mb={10}
          alignItems="center"
        >
          <Icon as={FiPhoneCall} boxSize={45} color={"green"} />
          <Stack alignItems="flex-start" lineHeight={1}>
            <Text letterSpacing={1} fontWeight={"800"} fontSize={20}>
              Contacto
            </Text>
            <Link
              as="a"
              href="tel:3123032690"
              letterSpacing={1}
              color={"#676D8E"}
              fontWeight={"100"}
              fontSize={17}
            >
              📲 312 303 2690
            </Link>
            <Link
              as="a"
              href="tel:3122560275"
              letterSpacing={1}
              color={"#676D8E"}
              fontWeight={"100"}
              fontSize={17}
            >
              📲 312 256 0275
            </Link>
            <Link
              as={"a"}
              href="mailto:vazcosolar@gmail.com"
              letterSpacing={1}
              color={"#676D8E"}
              fontWeight={"100"}
              fontSize={17}
            >
              ✉️ vazcosolar@gmail.com
            </Link>
          </Stack>
        </Stack>
        <Stack
          flex={1}
          mb={10}
          direction="row"
          justifyContent="center"
          spacing={30}
          textAlign="left"
          alignItems="center"
        >
          <Icon as={TfiMapAlt} boxSize={45} color={"green"} />
          <Stack alignItems="flex-start" lineHeight={1}>
            <Text letterSpacing={1} fontWeight={"800"} fontSize={20}>
              Ubicacion
            </Text>
            <Text
            as="a"
                href="https://maps.app.goo.gl/bb71RCb1PcHDFqh39?g_st=iw"
              letterSpacing={1}
              color={"#676D8E"}
              fontWeight={"100"}
              fontSize={17}
              textAlign={"left"}
              lineHeight={1.2}
            >
              📍 Torres Quintero #256; Villa de Álvarez, Colima
            </Text>
          </Stack>
        </Stack>
        <Stack
          className="div-section-information"
          flex={1}
          direction="row"
          justifyContent="center"
          bg={"#2B9B1F"}
          alignItems="center"
          position="relative"
          borderRadius="10px"
        >
          <Stack padding="30px">
            <Stack
              direction="row"
              justifyContent="center"
              mb={10}
              textAlign="left"
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={135}
              >
                <Icon as={BsPlayCircle} boxSize={35} color={"white"} />
              </Box>
              <Text
                color="#FFFFFF"
                fontSize={20}
                fontWeight={600}
                lineHeight="1.3em"
                textTransform="capitalize"
                noOfLines={3}
              >
                La Mejor Empresa De Energia Renovable
              </Text>
            </Stack>
            <Stack
              spacing={30}
              justifyContent="center"
              alignItems="flex-start"
              textAlign="left"
            >
              <Text color="#FFFFFF" fontSize={15}>
                Utilizamos solo los mejores paneles solares y componentes del
                mercado. Nuestra atención al detalle y estándares rigurosos
                garantizan que cada instalación es de la más alta calidad.
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SectionInformation;
