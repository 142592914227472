import React from 'react'

import { Box, Image } from '@chakra-ui/react'

function CardService({ service }: { service: any }) {

  return (
    <Box flex={1} borderRadius='10px' overflow='hidden' boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' h='auto' >
      <Image src={service.imageUrl} alt={service.imageAlt} maxH={200} width={"100%"} objectFit={'cover'} />

      <Box display='flex' justifyContent={'center'} p={5} fontSize='20px' fontWeight={600} lineHeight={'1.2em'} letterSpacing={1}>
        {service.title}
      </Box>

    </Box>
  )
}

export default CardService