import React from 'react'

import { Icon, Stack, Text } from '@chakra-ui/react'
import photo1 from '../assets/img/photo1.jpg'
import photo2 from '../assets/img/photo2.jpg'
import photo3 from '../assets/img/photo3.jpg'

import { AiOutlineCheck } from "react-icons/ai";



function SectionExperience() {
    return (
        <Stack id="#nosotros" w='100%' alignItems='center' justifyContent='center' mt={18}>
            <Stack direction={{
                base: 'column',
                lg: 'row'
            }} w={'80%'} h={'100%'}
                left={0} top={0} justifyContent='center' alignItems='flex-start'
                spacing={{
                    base: 10,
                    md: 12,
                    lg: 18,
                }}>
                <Stack flex={{
                    base: 'none',
                    lg: 1,
                }} height={{
                    base: 400,
                    md: 600,
                    lg: 800
                }} alignItems={'center'} width={"100%"}>
                    <Stack
                        backgroundImage={`linear-gradient( rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(${photo1})`} w={'100%'} h={'100%'}
                        backgroundPosition='center'
                        backgroundSize='cover'
                        borderRadius={15} position='relative'>
                        <Stack backgroundImage={photo2}
                            w={'50%'} h={'50%'}
                            backgroundSize='cover' borderRadius={10}
                            border='20px solid #FFFFFF' position='absolute'
                            bottom={-10} left={-10}>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack flex={1} spacing={{
                    base: 5,
                    md: 8,
                    lg: 15
                }} paddingLeft={{
                    base: 0,
                    md: 30
                }} mt={{
                    base: 10,
                    md: 0
                }}>
                    <Stack backgroundColor='#DAFFD5' borderRadius='1px 1px 5px 5px' marginBottom='10px' padding='0px 20px 10px 20px' width='200px'>
                        <Text color='#000' fontSize='14px' fontWeight='600' lineHeight='1.2em' letterSpacing='1.5px' marginTop='-8px'>NOSOTROS</Text>
                    </Stack>
                    <Stack padding={5} textAlign='left'>
                        <Text fontSize={35} fontWeight='bold' letterSpacing={1} color='#000'>
                            6 años de experiencia en energía solar nos respaldan
                        </Text>
                        <Text fontSize={16} fontFamily='monospace' fontWeight='100' color='#adadad'>
                            Tu satisfacción es nuestra prioridad. Desde la consulta inicial hasta el mantenimiento a largo plazo, estamos contigo en cada paso del camino. Nuestro equipo está dedicado a proporcionar un servicio excepcional y a responder cualquier pregunta que puedas tener.
                        </Text>
                    </Stack>
                    <Stack bg='#F9F9FC' borderRadius='10px 10px 10px 10px' padding={22} borderWidth='0px 0px 0px 5px' borderColor='#2B9B1F' borderStyle='solid' textAlign='left' w='90%'>
                        <Text fontStyle='oblique' textShadow={1} fontSize={16}  >
                            Creemos en hacer que la energía solar sea accesible para todos. Nuestros precios son competitivos, y ofrecemos opciones de financiamiento para adaptarnos a diferentes presupuestos.
                        </Text>
                    </Stack>
                    <Stack padding={10} direction={{
                        base: 'column',
                        md: 'row'
                    }} width='100%' textAlign={'left'}>
                        <Stack justifyContent='center' justifyItems='center'  >
                            <Stack direction='row' spacing={10}>
                                <Icon as={AiOutlineCheck} color={'#2B9B1F'} /><Text fontWeight={'100'}>Energía Limpia y Renovable</Text>
                            </Stack>
                            <Stack direction='row' spacing={10}>
                                <Icon as={AiOutlineCheck} color={'#2B9B1F'} /><Text fontWeight={'100'}>Ahorro Económico</Text>
                            </Stack>
                            <Stack direction='row' spacing={10}>
                                <Icon as={AiOutlineCheck} color={'#2B9B1F'} /><Text fontWeight={'100'}>Amigable con el Ambiente</Text>
                            </Stack>
                            <Stack direction='row' spacing={10}>
                                <Icon as={AiOutlineCheck} color={'#2B9B1F'} /><Text fontWeight={'100'}>Inversión a Largo Plazo</Text>
                            </Stack>
                        </Stack>
                        <Stack backgroundImage={photo3}
                            minH={200}
                            width={"80%"}
                            mt={{
                                base: 10,
                                md: 0,
                            }}
                            backgroundPosition='center' backgroundSize='cover' borderRadius={10}>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default SectionExperience