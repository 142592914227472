import React from 'react'

import { Flex } from '@chakra-ui/react';
import SectionInformation from '../components/SectionInformation';
import Banner from '../components/Banner';
import SectionExperience from '../components/SectionExperience';
import SectionWhyChoose from '../components/SectionWhyChooseUs';
import SectionServices from '../components/SectionOurServices';
import SectionMakeAppointment from '../components/SectionMakeAppointment';
import Footer from '../components/Footer';



function HomeScreen() {
  return (
    <Flex direction='column' paddingX={0}>
      <Banner />
      <SectionInformation />
      <SectionExperience />
      <SectionWhyChoose />
      <SectionServices />
      <SectionMakeAppointment />
      {/* <Footer /> */}
    </Flex>
  )
}

export default HomeScreen