import { Flex, Icon, Stack, Text } from '@chakra-ui/react'
import React from 'react'

import { AiOutlineThunderbolt } from 'react-icons/ai'
import { MdOutlineEngineering } from "react-icons/md"
import { GiReceiveMoney } from 'react-icons/gi'
import { FiUsers } from 'react-icons/fi'
import photo1 from '../assets/img/photo1.jpg'

function SectionWhyChoose() {
    return (
        <Flex id="porquevazco" w='100%' direction={{
            base: 'column',
            md: 'row'
        }} mt={{
            base: 20,
            md: 80
        }} >
            <Stack flex={1} marginTop={{
                base: 0,
                md: '-40px'
            }} marginBottom={10} borderRadius='0px 20px 0px 0px' bg={'#181C28'} padding={{
                base: 10,
                md: 55
            }} >
                <Stack backgroundColor='#2B9B1F' borderRadius='1px 1px 5px 5px' marginBottom='10px' padding='0px 20px 10px 20px' textAlign='center' w={'200px'}>
                    <Text color='#FFF' fontSize='14px' fontWeight='600' lineHeight='1.2em' letterSpacing='1.5px' marginTop='-8px'>POR QUE VAZCO?</Text>
                </Stack>
                <Stack textAlign={'left'}  >
                    <Text color={'#FFF'} fontSize={36} fontWeight={600} lineHeight={'1.2em'} textTransform={'capitalize'}>Nuestra pasión es iluminar el futuro de Colima con energía limpia y renovable.</Text>
                    <Text color={'#C4C4C4'} fontSize={16} fontWeight={400} lineHeight={'1.5em'} >Creemos en hacer que la energía solar sea accesible para todos. Nuestros precios son competitivos, y ofrecemos opciones de financiamiento para adaptarnos a diferentes presupuestos.</Text>
                </Stack>
                <Stack mt={{
                    base: 10,
                    md: 0
                }}
                    gap={{
                        base: 10,
                        md: 0,
                    }} direction={{
                        base: 'column',
                        md: 'row'
                    }} width='100%'>
                    <Stack textAlign={'left'} flex={1} padding={{
                        base: 0,
                        md: 10
                    }}>
                        <Stack w={65} h={65} borderRadius={10} justifyContent={'center'} alignItems={'center'} bg={'#2B9B1F'}>
                            <Icon as={AiOutlineThunderbolt} boxSize={30} color={'#FFF'} />
                        </Stack>
                        <Text color={'#FFF'} fontSize={'24px'} fontWeight={600} lineHeight={'1.3em'} letterSpacing={1}>Estrategia Energética</Text>
                        <Text color={'#C4C4C4'} fontSize={'15px'} fontWeight={400} lineHeight={'1.5em'} >En Vazco Solar, diseñamos soluciones solares a medida, enfocadas en maximizar la eficiencia y el rendimiento según tus necesidades específicas.</Text>
                    </Stack>
                    <Stack textAlign={'left'} flex={1} padding={{
                        base: 0,
                        md: 10
                    }}>
                        <Stack w={65} h={65} borderRadius={10} justifyContent={'center'} alignItems={'center'} bg={'#FFF'}>
                            <Icon as={MdOutlineEngineering} boxSize={30} color={'#2B9B1F'} />
                        </Stack>
                        <Text color={'#FFF'} fontSize={'24px'} fontWeight={600} lineHeight={'1.3em'} letterSpacing={1}>Ingenieros Capacitados</Text>
                        <Text color={'#C4C4C4'} fontSize={'15px'} fontWeight={400} lineHeight={'1.5em'} >Contamos con un equipo de ingenieros expertos en energía solar, comprometidos con la excelencia en cada instalación.</Text>
                    </Stack>
                </Stack>
                <Stack direction={{
                    base: 'column',
                    md: 'row'
                }} gap={{
                    base: 10,
                    md: 0
                }} mt={{
                    base: 10,
                    md: 0
                }} width='100%'>
                    <Stack textAlign={'left'} flex={1} padding={{
                        base: 0,
                        md: 10
                    }}>
                        <Stack w={65} h={65} borderRadius={10} justifyContent={'center'} alignItems={'center'} bg={'#FFF'}>
                            <Icon as={GiReceiveMoney} boxSize={30} color={'#2B9B1F'} />
                        </Stack>
                        <Text color={'#FFF'} fontSize={'24px'} fontWeight={600} lineHeight={'1.3em'} letterSpacing={1}>Ahorra Dinero</Text>
                        <Text color={'#C4C4C4'} fontSize={'15px'} fontWeight={400} lineHeight={'1.5em'} >Con Vazco Solar, reduce tus facturas eléctricas y aprovecha incentivos y financiamientos para una transición solar asequible.</Text>
                    </Stack>
                    <Stack textAlign={'left'} flex={1} padding={{
                        base: 0,
                        md: 10
                    }}>
                        <Stack w={65} h={65} borderRadius={10} justifyContent={'center'} alignItems={'center'} bg={'#2B9B1F'}>
                            <Icon as={FiUsers} boxSize={30} color={'#FFF'} />
                        </Stack>
                        <Text color={'#FFF'} fontSize={'24px'} fontWeight={600} lineHeight={'1.3em'} letterSpacing={1}>Consultoría Gratuita</Text>
                        <Text color={'#C4C4C4'} fontSize={'15px'} fontWeight={400} lineHeight={'1.5em'} >Ofrecemos consultorías sin costo, guiándote en cada paso hacia las ventajas y beneficios de la energía solar.</Text>
                    </Stack>
                </Stack>
            </Stack>
            <Stack flex={1} backgroundImage={`linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.6)),url(${photo1})`} backgroundRepeat='no-repeat' backgroundSize='cover' borderRadius='0px 0px 0px 20px'>
            </Stack>
        </Flex>
    )
}

export default SectionWhyChoose