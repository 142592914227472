

import { Stack, Text } from '@chakra-ui/react'
import CardService from '../CardService'

function Sectionservices() {

  const services = [

    {
      imageUrl: '/assets/img/paneles-solares.jpeg',
      imageAlt: 'Paneles Solares',
      title: 'Paneles Solares'

    },
    {
      imageUrl: '/assets/img/sistemas-aislados.jpeg',
      imageAlt: 'Sistemas Aislados',
      title: 'Sistemas Aislados'

    },
    {
      imageUrl: '/assets/img/calentadores-solares.jpeg',
      imageAlt: 'Calentador Solar',
      title: 'Calentadores Solares',
    },
    {
      imageUrl: '/assets/img/colectores-solares.jpeg',
      imageAlt: 'Colectores Solares',
      title: 'Colectores Solares',
    },

  ]


  return (
    <Stack id="servicios" w='100%' p={{
      base: 0,
      md: 10
    }} mt={{
      base: 10,
      md: 40,
    }} alignItems={'center'}>
      <Stack w={'80%'} h={'auto'} justifyContent={'center'} spacing={50}>
        <Stack direction={'row'} w='100%'>
          <Stack w={'100%'} h={'auto'} alignItems={'flex-start'}>
            <Stack backgroundColor='#E0FFDD' borderRadius='1px 1px 5px 5px' marginBottom='10px' padding='0px 15px 10px 15px' textAlign='center' w={'auto'}>
              <Text color='#000' fontSize='14px' fontWeight='600' lineHeight='1.2em' letterSpacing='1.5px' marginTop='-8px'>NUESTROS SERVICIOS</Text>
            </Stack>
            <Text textAlign={'left'} color={'#767D8E'} fontSize={16} fontWeight={400} lineHeight={'1.5em'} >En Vazco Solar, entendemos que cada hogar y negocio tiene necesidades únicas. Nuestra estrategia energética se centra en evaluar detenidamente tus requerimientos y diseñar soluciones a medida que maximicen la eficiencia y el rendimiento de tu sistema solar. A través de una planificación meticulosa y un enfoque proactivo, garantizamos una transición suave y efectiva hacia la energía solar.</Text>
          </Stack>
        </Stack>
        <Stack width={'100%'} direction={{
          base: 'column',
          md: 'row',
        }} alignItems={'center'}
          justifyContent='center'
          spacing={22}>

          {services.map((srv, index) =>
            <CardService key={index} service={srv} />

          )}

        </Stack>
      </Stack>

    </Stack>

  )
}

export default Sectionservices